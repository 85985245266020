// client;
"use client";
import { FacebookProvider, CustomChat } from "react-facebook";

const Facebookmsg = () => {
  return (
    <FacebookProvider appId="248221921010183" chatSupport>
      <CustomChat pageId="119553881070474" minimized={false} />
    </FacebookProvider>
  );
};

export default Facebookmsg;
