import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Privacy Policy</h2>
          <p>
          APP PRIVACY POLICY The developer of this app has informed us that the practices of the app may involve the handling of data as described below. For more information, see the developer's<a href="https://sites.google.com/d/16FNt-vLUbk17eOuT1bDWf3CtO3yAg3uZ/p/1GBfgtJsi5PF2nvtv5-CXBBu51GYDC9yb/edit" style={{color: '#000'}}> privacy policy</a>.
          </p>
        </div>
        <div className="row  PrivacyimgSize">
        <img src="img/PrivacyPolicy.jpeg" className="img-responsive" alt="" />
                  {/* {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"} */}
        </div>
      </div>
    </div>
  );
};
